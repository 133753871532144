import React from 'react'
import Img from 'gatsby-image'
import Swiper from 'react-id-swiper'
import Fade from 'react-reveal/Fade'

const TheSwiper = ({ text, images }) => {
  const params = {
    slidesPerView: 'auto',
    grabCursor: true,
    pagination: false,
  }

  return (
    <Fade delay={225} duration={2250}>
      <div className="custom-swiper">
        <Swiper {...params}>
          <div className="custom-swiper__slide-text">
            <div className="h-full flex items-center">
              {text && (
                <div
                  className="rte font-light text-lg"
                  dangerouslySetInnerHTML={{ __html: text.html }}
                ></div>
              )}
            </div>
          </div>
          {images.map((item, index) => (
            <div key={index} className="custom-swiper__slide-image">
              <Img
                fluid={item.image.localFile.childImageSharp.fluid}
                alt={item.image.alt}
              />
              {item.image.alt && (
                <p className="text-sm font-light mt-4">{item.image.alt}</p>
              )}
            </div>
          ))}
        </Swiper>
      </div>
    </Fade>
  )
}

export default TheSwiper
