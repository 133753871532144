import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Hero from '../components/Hero'
// import HeroImage from '../images/hero/future.jpg'
import ScrollIndicator from '../components/Ui/ScrollIndicator'
import Footer from '../components/Footer'
import ContentBlocksIntroduction from '../components/ContentBlocks/ContentBlocksIntroduction'
import ContentBlocksLead from '../components/ContentBlocks/ContentBlocksLead'
import ContentBlocksDefault from '../components/ContentBlocks/ContentBlocksDefault'
import Swiper from '../components/Swiper'

const footerLinks = ['past', 'present']

const FuturePage = ({ location, data }) => {
  const page = data.prismicFuture.data
  const environmentImages = [
    page.environment_primary_image_mobile.localFile.childImageSharp.fluid,
    {
      ...page.environment_primary_image.localFile.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  return (
    <Layout>
      <SEO
        title={page.meta_title}
        description={page.meta_description}
        location={location}
      />
      <ScrollIndicator />
      <Hero
        title="Future"
        preTitle="Okains Bay"
        image={data.heroImage.childImageSharp.fluid}
        gradient
        scrollLink
        caption="The Summerton Family — Tom, Greg, Jackie, Georgia and Libby"
      />
      <div id="discover" className="py-48">
        <div className="mb-56">
          <ContentBlocksLead text={page.lead_text} image={page.lead_image} />
        </div>
        <div className="mb-56">
          <ContentBlocksIntroduction text={page.lead_secondary_text} />
        </div>
        <div className="mb-32">
          <ContentBlocksDefault
            title={page.main_primary_title}
            text={page.main_primary_text}
            image={page.main_primary_image}
          />
        </div>
        <Fade delay={225} bottom distance={'25px'} duration={2250}>
          <div
            className="w-3/4 md:w-1/2 mx-auto text-center text-lg md:text-xl lg:text-2xl mb-32"
            dangerouslySetInnerHTML={{
              __html: page.main_secondary_text.html,
            }}
          ></div>
        </Fade>
        <div className="mb-32">
          <ContentBlocksDefault
            reversed
            title={page.main_tertiary_title}
            text={page.main_tertiary_text}
            image={page.main_tertiary_image}
          />
        </div>
        <div className="mb-32 lg:gutters text-center">
          <div className="relative h-screen md:gutters-y">
            <Fade delay={225} duration={2250}>
              <div className="w-full h-full">
                <Img
                  className="w-full h-full"
                  fluid={environmentImages}
                  alt={page.environment_primary_image.alt}
                />
              </div>
            </Fade>
            <div className="absolute inset-0 flex items-center justify-center">
              <Fade delay={450} duration={2250}>
                <div className="w-3/4 xl:w-1/2 mx-auto md:mt-16">
                  <h4 className="text-5xl md:text-6xl lg:text-7xl tracking-wide uppercase font-medium mb-3 leading-none">
                    {page.environment_primary_title}
                  </h4>
                  <div
                    className="text-lg md:text-xl lg:text-2xl font-light"
                    dangerouslySetInnerHTML={{
                      __html: page.environment_primary_text.html,
                    }}
                  ></div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <div className="w-3/4 md:w-1/2 mx-auto text-center text-lg md:text-xl lg:text-2xl mb-32">
          <Fade delay={225} bottom distance={'25px'} duration={2250}>
            <div
              className="font-light"
              dangerouslySetInnerHTML={{
                __html: page.primary_text.html,
              }}
            />
          </Fade>
        </div>
        <div className="mb-32">
          <ContentBlocksDefault
            text={page.secondary_text}
            image={page.secondary_image}
          />
        </div>
        <div className="mb-32">
          <Swiper text={page.slider_text} images={page.slider_images} />
        </div>
        <Fade delay={225} bottom distance={'25px'} duration={2250}>
          <div className="w-3/4 md:w-1/2 mx-auto text-center text-lg md:text-xl lg:text-2xl font-light">
            <div
              dangerouslySetInnerHTML={{
                __html: page.tertiary_text.html,
              }}
            />
            <AniLink className="button mt-12" to="/the-kawatea">
              {page.link_text}
            </AniLink>
          </div>
        </Fade>
      </div>
      <Footer links={footerLinks} />
    </Layout>
  )
}

FuturePage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageFutureQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero/future.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prismicFuture {
      data {
        meta_title
        meta_description
        lead_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1684, maxHeight: 1122) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        lead_text {
          html
          text
        }
        lead_secondary_text {
          html
          text
        }
        lead_secondary_title
        lead_text {
          html
          text
        }
        main_primary_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1684, maxHeight: 1122) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        main_primary_title
        main_primary_text {
          html
          text
        }
        main_tertiary_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1684, maxHeight: 1122) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        main_tertiary_title
        main_tertiary_text {
          html
          text
        }
        main_secondary_text {
          html
          text
        }
        environment_primary_title
        environment_primary_text {
          html
          text
        }
        environment_primary_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2694, maxHeight: 1602) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        environment_primary_image_mobile {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        slider_text {
          html
          text
        }
        slider_images {
          image {
            alt
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 1030, maxHeight: 660) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        primary_text {
          html
          text
        }
        secondary_text {
          html
          text
        }
        secondary_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1684, maxHeight: 1048) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tertiary_text {
          html
          text
        }
        link_text
      }
    }
  }
`

export default FuturePage
